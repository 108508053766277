import topic from '../topic';
export const mqcdAPI = {
  getMqcds: topic.mqcdTopic + '/getMqcds',
  addMqcd: topic.mqcdTopic + '/addMqcd',
  deleteMqcdByIds: topic.mqcdTopic + '/deleteMqcdByIds',
  getMqcdById: topic.mqcdTopic + '/getMqcdById',
  getMqcdsByRecycleBin: topic.mqcdTopic + '/getMqcdsByRecycleBin',
  cancelDelete: topic.mqcdTopic + '/cancelDelete',
  addMtrlToMqcd: topic.mqcdTopic + '/addMtrlToMqcd',
  getDeleteMqcds: topic.mqcdTopic + '/getDeleteMqcds'
};
