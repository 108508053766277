import { render, staticRenderFns } from "./MdtfAddMainO.vue?vue&type=template&id=4ed256fa&scoped=true&"
import script from "./MdtfAddMainO.vue?vue&type=script&lang=js&"
export * from "./MdtfAddMainO.vue?vue&type=script&lang=js&"
import style0 from "./MdtfAddMainO.vue?vue&type=style&index=0&id=4ed256fa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed256fa",
  null
  
)

export default component.exports