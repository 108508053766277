<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('mdtfForm')" @cancel="cancel" :stateFlag="false" />
    <el-form ref="mdtfForm" :model="mdtfForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="二维码编号" prop="qrcd_no" :rules="[{ required: true, message: ' ' }]">
            <el-input
              :disabled="disableFlag"
              @keydown.enter.native="inputDown"
              ref="inputs"
              maxlength="10"
              focus
              @input="mdtfForm.qrcd_no = helper.keepEngNum(mdtfForm.qrcd_no)"
              v-model="mdtfForm.qrcd_no"
              placeholder="请填写二维码编号"
              show-word-limit
            >
              <el-button slot="append" type="info" @click="emptyInput" class="vd_btn">清空</el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="材料名称" prop="mdrl_name">
            <el-input disabled maxlength="30" v-model="mdtfForm.mdrl_name" placeholder="请填写材料名称" show-word-limit> </el-input>
          </el-form-item>
          <el-form-item label="材料规格" prop="mdrl_spec">
            <el-input disabled v-model="mdtfForm.mdrl_spec" maxlength="11" show-word-limit placeholder="请填写材料长度" />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="材料编号" prop="mdrl_no">
            <el-input disabled maxlength="10" v-model="mdtfForm.mdrl_no" placeholder="请填写材料编号" show-word-limit> </el-input>
          </el-form-item>
          <el-form-item label="材料类型" prop="mdrl_type">
            <el-select size="small" v-model="mdtfForm.mdrl_type" placeholder="请选择材料类型" clearable disabled>
              <el-option v-for="item in mdrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="库位" prop="mqcd_bin" :rules="[{ required: true, message: ' ' }]">
            <el-select disabled v-model="mdtfForm.mqcd_bin" placeholder="请选择库位" clearable>
              <el-option v-for="item in binList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="现有库存" prop="mqcd_num">
            <el-input disabled v-model="mdtfForm.mqcd_num" placeholder="请填写现有库存" show-word-limit />
          </el-form-item>
          <el-form-item label="数量" prop="mdtf_num" :rules="[{ required: true, message: ' ' }]">
            <el-input v-model="mdtfForm.mdtf_num" placeholder="请填写数量" show-word-limit />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mdtfAPI } from '@api/modules/mdtf';
import { qrcdAPI } from '@api/modules/qrcd';
import { optnAPI } from '@api/modules/optn';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import { mqcdAPI } from '@api/modules/mqcd';

export default {
  name: 'mdtfAddMain',
  components: {
    addHeader,
    inputUser
  },
  data() {
    return {
      mdtfForm: {
        mdtf_type: null,
        qrcd_no: null,
        mdrl_id: null,
        mdrl_no: null,
        mdrl_name: null,
        mdrl_unit: null,
        mdrl_type: null,
        mdrl_num: null,
        mdrl_width: null,
        mdrl_color: null,
        mdrl_spec: null,
        qrcd_bin: null,
        user_id: null,
        stff_id: null,
        dept_id: null,
        mdtf_length: null,
        mdtf_width: null,
        status: 0,
        mdtf_remark: null,
        key: 0,
        belo_dept_id: null //所属部门
      },
      binList: [],
      mdrlType: [
        { id: 1, label: '钢材' },
        { id: 2, label: '辅材' }
      ],
      mdrlTypeGroupOpt: [],
      title: '',
      binFlag: false,
      numFlag: false,
      mdrlType1: '原面料',
      mdrlType2: '辅料',
      mdrlType3: '包材',
      mdrlType4: '',
      disableFlag: false,
      option: [] //所属部门
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  mounted() {
    this.$refs.inputs.focus();
  },
  methods: {
    initData() {
      this.getMdtfType();
      this.getBinList();
    },
    // 获取库位信息
    getBinList() {
      get(optnAPI.getAllContent, { perm_id: 10006 }).then(res => {
        if (res.data.code === 0) {
          this.binList = res.data.data;
        }
      });
    },
    // 获取单位
    getMdtfType() {
      get(optnAPI.getAllContent, { perm_id: 10005 }).then(res => {
        if (res.data.code === 0) {
          this.mdrlTypeGroupOpt = res.data.data;
        }
      });
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.saveInfo1();
          } else {
            console.log('error submit!');
            return false;
          }
        });
      });
    },
    // 保存出库
    saveInfo1() {
      if (this.mdtfForm.mqcd_bin) {
        const staffForm = this.$refs.userData.getData();
        this.mdtfForm.mdtf_type = 1;
        this.mdtfForm = Object.assign(this.mdtfForm, staffForm);
        post(mdtfAPI.stockOut, this.mdtfForm)
          .then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '保存成功'
              });
              this.disableFlag = false;
              const permId = this.$route.query.perm_id;
              this.jump(`/mdtf_list?perm_id=${permId}`);
              this.resetForm('mdtfForm');
              this.$refs.inputs.focus();
              this.$EventBus.$emit('uploading', 12345);
            } else if (res.data.code === 7) {
              this.$message({
                type: 'error',
                message: '库位中已有此材料'
              });
            } else {
              this.initData();
            }
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '请输入正确的二维码编号'
            });
            this.resetForm('mdtfForm');
          });
      } else {
        this.$message({
          type: 'error',
          message: '该材料暂未入库,请先入库'
        });
      }
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('mdtfForm');
        this.$router.go(-1);
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      });
    },
    // 扫码按下enter键导入材料
    inputDown() {
      if (!this.mdtfForm.qrcd_no) {
        return this.$message.warning('请先输入二维码编号');
      }
      this.$refs.inputs.blur();
      get(mqcdAPI.getMqcdById, { mqcd_no: this.$refs.inputs.$options.propsData.value }).then(res => {
        if (res.data.code === 0) {
          this.disableFlag = true;
          this.mdrlExport(res.data.data.form);
        } else if (res.data.code === 7) {
          this.$message({
            type: 'error',
            message: '库位中已有此材料'
          });
        } else {
          this.initData();
        }
      });
    },
    // 清空
    emptyInput() {
      this.resetForm('mdtfForm');
      this.disableFlag = false;
    },
    // 材料出库导入确认
    mdrlExport(val) {
      let no = this.mdtfForm.qrcd_no;
      this.mdtfForm = val;
      this.mdtfForm.qrcd_no = no;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.mdtfForm.qrcd_bin = null;
      this.numFlag = false;
      this.mdtfForm.mdrl_num = null;
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_btn {
  color: #18c7ee !important;
}
</style>
